*{
  margin: 0;
  padding: 0;
  box-sizing: border-boxs;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f9fd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li{
  list-style: none;
}

h1{
  font-size: 1.5rem;
  letter-spacing: 2px;
}

h2{
  font-size: 2.5rem;
  letter-spacing: 2px;
  color: #1f3a0c;
  text-align: center;
}


h3{
  font-size: 1rem;
  color: #466a2d;
  letter-spacing: 2px;
  padding: 1em 1em;

}

h4{
  font-weight: 600;
  letter-spacing: 5px;
}

.detail{
  font-size: 1rem;
  display: grid;
  align-items: center;
  text-align: justify;
}

.title{
  height: 20px;
  max-height: 20px;
  font-size: 1rem;
  background: #d3e6a4;
  color: #4b7130;
  padding: .5em 1em;
  text-align: center;
  border-radius: 5px;
}

p{
  color: #888;
}
.active{
  border-bottom: 2px solid black;
}

.link{
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
  color: #555;
}


nav{
  width: 100%;
  height: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 2px 5px 3px 0 rgba(0,0,0,.5);
}
.container{
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid crimson; */
}

ul{
  width: 40%;
  display: flex;
  justify-content: flex-end;
  gap: 2em;
  /* border: 2px solid black; */
}

.search{
  width: 40%;
  margin: 5em auto;
  background: #fff;
  box-shadow: 2px 5px 3px 0 rgba(0,0,0,.5);;
}
.search > div{
  margin-bottom: 1em;
  padding: 1em 1em;
  overflow: hidden;
  /* border: 2px solid crimson; */
}
input{
  width: 95%;
  height: 45px;
  margin: 0 auto;
  background: #f1f6f9;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  padding: 0 .5em;
}

.all-coctails{
  width: 90%;
  margin: 3em auto;
}

.cards-sec{
  width: 100%;
  height: 100%;
  margin: 1em auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* gap: 10em; */
  /* border: 2px solid black; */
}

.card{
  width: 370px;
  height: 300px;
  margin: 8em 1em;
  /* border: 2px solid crimson; */
}


.card-head{
  height: 100%;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.card-head img{
  width: 100%;
  height: 100%;
}
.card-body{
  width: 100%;
  min-height: 160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
}

.card-body > div{
  margin: .3em .5em;
}

.btn{
  width: 100px;
  height: 35px;
  margin: 1em;
  background: #466a2d;
  color: #fff;
  letter-spacing: 5px;
  border-radius: 5px;
  text-transform: uppercase;
  border: none;
  transition: all .3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn:hover{
  background: #abc797;
  color: #3b5e23;
  cursor: pointer;
}

.btn-link{
  font-size: .8rem;
  letter-spacing: 5px;
  text-decoration: none;
  text-align: center;
}

.single-cocktail{
  width: 90%;
  margin: .5em auto;
  margin-bottom: 1em;
  display: grid;
  grid-template-rows: 90%;
  grid-template-columns: 35% 50%;
  grid-column-gap: 0;
  justify-content: start;
  align-items: center;
  /* border: 2px solid crimson; */
}


.single-cocktail img{
  width: 90%;
  height: 400px;
  border-radius: 5px;
}

.info{
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.info > div{
  display: flex;
  gap: .5em;
}

.about{
  width: 50%;
  margin: 4em auto;
}